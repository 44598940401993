import * as yup from "yup";

export const STATUS_PEDIDO = {
  AGUARDANDO: "A",
  PARC_ENTREGUE: "P",
  REALIZAR_AFERICAO: "R",
  ENTREGUE: "E",
  CANCELADO: "C",
  DEVOLVIDO: "D",
};

export const findStatusPedido = (status) => {
  switch (status) {
    case STATUS_PEDIDO.AGUARDANDO:
      return "Aguardando";
    case STATUS_PEDIDO.PARC_ENTREGUE:
      return "Parcialmente entregue";
    case STATUS_PEDIDO.REALIZAR_AFERICAO:
      return "Realizar aferição de compra";
    case STATUS_PEDIDO.ENTREGUE:
      return "Entregue";
    case STATUS_PEDIDO.CANCELADO:
      return "Cancelado";
    case STATUS_PEDIDO.DEVOLVIDO:
      return "Devolvido";
    default:
      return "";
  }
};

export const TIPO_FRETE = {
  FOB: "F",
  CIF: "C",
  PRIME: "P",
  TERCEIRO: "T",
  OUTRO: "O",
};

export const findTipoFrete = (tipoFrete) => {
  switch (tipoFrete) {
    case TIPO_FRETE.FOB:
      return "FOB";
    case TIPO_FRETE.CIF:
      return "CIF";
    case TIPO_FRETE.PRIME:
      return "PRIME";
    case TIPO_FRETE.TERCEIRO:
      return "Terceiro";
    case TIPO_FRETE.OUTRO:
      return "Outro";
    default:
      return "";
  }
};

export const yupSchema = yup.object().shape({
  data: yup.date().required("Esse campo é obrigatório"),
  status: yup
    .string()
    .oneOf(Object.values(STATUS_PEDIDO))
    .required("Esse campo é obrigatório"),
  valorTotal: yup.string().required("Esse campo é obrigatório"),
  supplier: yup.string().required("Esse campo é obrigatório"),
  shippingCompany: yup.string().required("Esse campo é obrigatório"),
  pesoBruto: yup.string().required("Esse campo é obrigatório"),
  tara: yup.string().required("Esse campo é obrigatório"),
  pesoLiquido: yup.string().required("Esse campo é obrigatório"),
  qtdFrutas: yup.string().required("Esse campo é obrigatório"),
  media: yup.string().required("Esse campo é obrigatório"),
  precoTonelada: yup.string().required("Esse campo é obrigatório"),
  dataDescarga: yup.date().required("Esse campo é obrigatório"),
  valorFrete: yup.string().required("Esse campo é obrigatório"),
  valorIcms: yup.string().required("Esse campo é obrigatório"),
  carregamento: yup.string().required("Esse campo é obrigatório"),
  totalPagarFrete: yup.string().required("Esse campo é obrigatório"),
  tipoFrete: yup
    .string()
    .oneOf(Object.values(TIPO_FRETE))
    .required("Esse campo é obrigatório"),
});

export const initialValues = {
  data: "",
  status: "",
  valorTotal: "",
  supplier: "",
  user: "",
  shippingCompany: "",
  agent: null,
  pesoBruto: "",
  tara: "",
  pesoLiquido: "",
  qtdFrutas: "",
  media: "",
  precoTonelada: "",
  dataDescarga: "",
  valorFrete: "",
  valorIcms: "",
  carregamento: "",
  adiantamentoFrete: "",
  totalPagarFrete: "",
  motorista: "",
  tipoFrete: "",
  caminhao: "",
  telefone: "",
  totalFinal: "",
  produtos: [],
  contas: [],
  observacoes: "",
  numPedido: "",
  gerarLote: "N",
};

import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import AdicionarFuncionario from "layouts/funcionarios";
import AdicionarProduto from "layouts/recebidos";
import { useEffect, useState } from "react";
import Service from "services/trans-estoque";
import { format } from "utils/formatDate";
import Itens from "./itens";
import { initialValues } from "./yupSchema";

export default function EditarDeposito({
  setShowEditState,
  currentIdAction,
  fetchData,
  fecharOnSave = false,
  warehouseId,
  warehouseList,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [showAddResponsavel, setShowAddResponsavel] = useState(false);
  const [showAddProduto, setShowAddProduto] = useState(false);
  const [showAddCriadoPor, setShowAddCriadoPor] = useState(false);
  const [childData, setChildData] = useState({});

  const [currentItem, setCurrentItem] = useState(null);

  const useStyles = makeStyles({
    tabs: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#81BF52",
      },
      "& .MuiTab-root": {
        color: "#425d73 !important",
      },
      "& .MuiTab-root.Mui-selected": {
        backgroundColor: "#81bf52ab",
        color: "#FFFFFF !important",
      },
    },
  });

  const classes = useStyles();

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      values.produtos.forEach((produto) => {
        if (produto.qtdRetirada > produto.qtdRecebida) {
          setSuccess(false);
          setLoading(false);
          throw new Error(
            "Quantidade retirada maior que a quantidade recebida"
          );
        }
      });

      try {
        await Service.update(currentIdAction, values);

        if (fecharOnSave) {
          fetchData();
          setShowEditState(false);
        } else {
          setSuccess(true);
        }
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }
      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);
      for (var key in formik.values) {
        if (key.includes("data"))
          formik.setFieldValue(
            key,
            fields[key] !== undefined && fields[key] !== null
              ? format(new Date(fields[key]), "yyyy-MM-dd")
              : ""
          );
        else formik.setFieldValue(key, fields[key]);
        const newData = {};
        newData[key] = fields[key];
        setChildData((prevState) => Object({ ...prevState, ...newData }));
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        if (showAddResponsavel) setShowAddResponsavel(false);
        if (showAddCriadoPor) setShowAddCriadoPor(false);
        if (showAddProduto) setShowAddProduto(false);

        if (!showAddProduto && !showAddResponsavel && !showAddCriadoPor) {
          setShowEditState(false);
          fetchData();
        }

        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, [showAddResponsavel, showAddCriadoPor, showAddProduto]);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Editar Transferência de Estoque
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowEditState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="warehouse"
                    name="warehouse"
                    label="Estoque / Depósito"
                    value={warehouseId?.nome}
                    disabled
                    InputLabelProps={{ readOnly: true }}
                  />
                  <TextField
                    id="usuario"
                    name="usuario"
                    label="Usuário criador da solicitação"
                    value={formik.values.usuario}
                    onChange={formik.handleChange}
                    disabled
                    InputLabelProps={{ readOnly: true }}
                  />
                  <TextField
                    id="dataDeTransferencia"
                    name="dataDeTransferencia"
                    label="Data da Transferência"
                    type="date"
                    value={formik.values.dataDeTransferencia}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dataDeTransferencia &&
                      Boolean(formik.errors.dataDeTransferencia)
                    }
                    helperText={
                      formik.touched.dataDeTransferencia &&
                      formik.errors.dataDeTransferencia
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    id="warehouseDestino"
                    name="warehouseDestino"
                    label="Depóstio de Destino"
                    value={formik.values.warehouseDestino._id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.warehouseDestino &&
                      Boolean(formik.errors.warehouseDestino)
                    }
                    helperText={
                      formik.touched.warehouseDestino &&
                      formik.errors.warehouseDestino
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    {warehouseList?.data
                      ?.filter((item) => item._id !== warehouseId.id)
                      .map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.descricao}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    id="observacoes"
                    name="observacoes"
                    label="Observação"
                    value={formik.values.observacoes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.observacoes &&
                      Boolean(formik.errors.observacoes)
                    }
                    helperText={
                      formik.touched.observacoes && formik.errors.observacoes
                    }
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />

                  <Stack
                    sx={{
                      gridColumn: "span 2",
                    }}
                  >
                    <Tabs
                      orientation={"horizontal"}
                      value={0}
                      className={classes.tabs}
                    >
                      <Tab label="Produtos a serem cautelados" />
                    </Tabs>
                    <Itens
                      {...{
                        formik,
                        loading,
                      }}
                      setShowAddProduto={setShowAddProduto}
                      setCurrentItem={setCurrentItem}
                      currentItem={currentItem}
                      isDetalhar={true}
                    />
                  </Stack>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Transferência de Estoque atualizado com sucesso!"
                          : "Ocorreu um erro ao atualizar Transferência de Estoque."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* forms alternativos */}
        {showAddProduto && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarProduto
              setShowAddProduto={setShowAddProduto}
              selectParent={setCurrentItem}
              disableForm={true}
              fromParent="trans-estoque"
              grupoProduto="ALMOXARIFADO"
              filterByWarehouse={warehouseId.id}
            />
          </MDBox>
        )}
        {showAddResponsavel && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFuncionario
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, responsavel: value })
                );
                selectParent("responsavel", value?.name);
              }}
              setShowAddFuncionario={setShowAddResponsavel}
            />
          </MDBox>
        )}

        {showAddCriadoPor && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFuncionario
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, criadoPor: value })
                );
                selectParent("criadoPor", value?.name);
              }}
              setShowAddFuncionario={setShowAddCriadoPor}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}

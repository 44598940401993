import HttpService from "./http.service";

class WarehouseStockService {
  pdf = async (id) => {
    const warehousesEndpoint = "/warehouse-stock/pdf";
    return await HttpService.get(`${warehousesEndpoint}/${id}`);
  };

  list = async (sortStr = null, filterStr = null) => {
    const warehousesEndpoint = `/warehouse-stock?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(warehousesEndpoint);
  };

  listByWarehouse = async (warehouseId, sortStr = null, filterStr = null) => {
    const warehousesEndpoint = `/warehouse-stock/warehouse/${warehouseId}?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(warehousesEndpoint);
  };

  paginate = async (
    page = 0,
    limit = 25,
    sortStr = null,
    filterStr = null,
    warehouseId
  ) => {
    const warehousesEndpoint = `/warehouse-stock/warehouse/${warehouseId}?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(warehousesEndpoint);
  };

  listForDashboard = async (warehouseId) => {
    const warehousesEndpoint = `/warehouse-stock/dashboard/${warehouseId}`;
    return await HttpService.get(warehousesEndpoint);
  };

  listForApplication = async (warehouseId) => {
    const warehousesEndpoint = `/warehouse-stock/application/${warehouseId}`;
    return await HttpService.get(warehousesEndpoint);
  };

  get = async (id) => {
    const warehousesEndpoint = "/warehouse-stock";
    return await HttpService.get(`${warehousesEndpoint}/${id}`);
  };

  filter = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const warehousesEndpoint = `/warehouse-stock/filter?${query}`;
    return await HttpService.get(warehousesEndpoint, data);
  };

  create = async (data) => {
    const warehousesEndpoint = "/warehouse-stock";
    return await HttpService.post(warehousesEndpoint, data);
  };

  update = async (id, data) => {
    const warehousesEndpoint = "/warehouse-stock";
    return await HttpService.put(`${warehousesEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const warehousesEndpoint = "/warehouse-stock";
    return await HttpService.delete(`${warehousesEndpoint}/${id}`);
  };
}

export default new WarehouseStockService();

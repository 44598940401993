import * as yup from "yup";

export const yupSchema = yup.object().shape({
  warehouse: yup.string().required("Esse campo é obrigatório"),
  warehouseDestino: yup.string().required("Esse campo é obrigatório"),
  usuario: yup.string().required("Esse campo é obrigatório"),
  dataDeTransferencia: yup.string().required("Esse campo é obrigatório"),
  produtos: yup.array(),
  observacoes: yup.string(),
});

export const initialValues = {
  warehouse: "",
  warehouseDestino: "",
  usuario: "",
  dataDeTransferencia: "",
  produtos: [],
  observacoes: "",
};

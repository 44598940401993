import * as yup from "yup";

export const ROLES = {
  ADMIN: "admin",
  BUYER: "comprador",
  SELLER: "vendedor",
  FINANCIAL: "financeiro",
  SUPPORT: "suporte",
};

export const findRole = (role) => {
  switch (role) {
    case ROLES.ADMIN:
      return "Administrador";
    case ROLES.BUYER:
      return "Comprador";
    case ROLES.SELLER:
      return "Vendedor";
    case ROLES.FINANCIAL:
      return "Financeiro";
    case ROLES.SUPPORT:
      return "Suporte";
    default:
      return "";
  }
};

export const AREAS = {
  ACCOUNT: "accounts",
  ACCOUNT_GROUP: "account-groups",
  AGENT: "agents",
  CUSTOMER: "customers",
  PAYMENT_METHOD: "payment-methods",
  PERMISSION: "permissions",
  PRODUCT: "products",
  PRODUCT_GROUP: "product-groups",
  SHIPPING_COMPANY: "shipping-companies",
  SUB_GROUP_ACCOUNT: "sub-group-accounts",
  SUPPLIER: "suppliers",
  USER: "users",
  PAYABLE: "payables",
  RECEIVABLE: "receivables",
  PURCHASE: "purchases",
  SALE: "sales",
  REPORT: "reports",
  STOCK: "stock",
  DASHBOARD_REPORTS: "dashboard-reports",
  LASTNFENUMBER: "lastnfenumbers",
  WAREHOUSE: "warehouses",
  PURCHASE_WAREHOUSE: "purchases-warehouse",
  RECEIVEDS: "receiveds",
  STOCK_TRANSACTIONS: "stock-transactions",
  TRANSFER_STOCK: "trans-estoque",
  STOCK_TRANSITORY: "stock-transitory",
  APLICACAO: "aplicacao",
  DEVOLUCAO: "devolucao",
  BATCH_OF_SETTLEMENTS: "batch-of-settlements",
};

export const AREAS_LIST = [
  {
    value: AREAS.ACCOUNT,
    label: "Contas Bancárias",
  },
  {
    value: AREAS.ACCOUNT_GROUP,
    label: "Centro de Custo",
  },
  {
    value: AREAS.AGENT,
    label: "Agenciadores",
  },
  {
    value: AREAS.CUSTOMER,
    label: "Clientes",
  },
  {
    value: AREAS.PAYMENT_METHOD,
    label: "Formas de Pagamento",
  },
  {
    value: AREAS.PERMISSION,
    label: "Acessos do Usuário",
  },
  {
    value: AREAS.PRODUCT,
    label: "Lista de Produtos",
  },
  {
    value: AREAS.PRODUCT_GROUP,
    label: "Grupos de Produtos",
  },
  {
    value: AREAS.SHIPPING_COMPANY,
    label: "Transportadoras",
  },
  {
    value: AREAS.SUB_GROUP_ACCOUNT,
    label: "Natureza de Lançamento",
  },
  {
    value: AREAS.SUPPLIER,
    label: "Fornecedores",
  },
  {
    value: AREAS.USER,
    label: "Funcionários",
  },
  {
    value: AREAS.PAYABLE,
    label: "Contas a Pagar",
  },
  {
    value: AREAS.RECEIVABLE,
    label: "Contas a Receber",
  },
  {
    value: AREAS.PURCHASE,
    label: "Pedidos de Compra",
  },
  {
    value: AREAS.SALE,
    label: "Pedidos de Venda",
  },
  {
    value: AREAS.REPORT,
    label: "Relatórios",
  },
  {
    value: AREAS.WAREHOUSE,
    label: "Depósitos",
  },
  {
    value: AREAS.STOCK,
    label: "Estoque",
  },
  {
    value: AREAS.STOCK_TRANSACTIONS,
    label: "Entradas e Saídas",
  },
  {
    value: AREAS.TRANSFER_STOCK,
    label: "Transferência de Estoque",
  },
  {
    value: AREAS.PURCHASE_WAREHOUSE,
    label: "Pedidos de Compra no Almoxarifado",
  },
  {
    value: AREAS.RECEIVEDS,
    label: "Recebimento no Almoxarifado",
  },
  {
    value: AREAS.STOCK_TRANSITORY,
    label: "Estoque Transitório",
  },
  {
    value: AREAS.DASHBOARD_REPORTS,
    label: "Relatórios do Dashboard",
  },
  {
    value: AREAS.LASTNFENUMBER,
    label: "Últimos Números de NF-e",
  },
  {
    value: AREAS.APLICACAO,
    label: "Aplicação",
  },
  {
    value: AREAS.DEVOLUCAO,
    label: "Devolução",
  },
  {
    value: AREAS.BATCH_OF_SETTLEMENTS,
    label: "Lotes de Baixa",
  },
];

export const findArea = (area) => {
  switch (area) {
    case AREAS.ACCOUNT:
      return "Contas Bancárias";
    case AREAS.ACCOUNT_GROUP:
      return "Centro de Custo";
    case AREAS.AGENT:
      return "Agenciadores";
    case AREAS.CUSTOMER:
      return "Clientes";
    case AREAS.PAYMENT_METHOD:
      return "Formas de Pagamento";
    case AREAS.PERMISSION:
      return "Permissões";
    case AREAS.PRODUCT:
      return "Produtos";
    case AREAS.PRODUCT_GROUP:
      return "Grupos de Produtos";
    case AREAS.SHIPPING_COMPANY:
      return "Transportadoras";
    case AREAS.SUB_GROUP_ACCOUNT:
      return "Natureza de Lançamento";
    case AREAS.SUPPLIER:
      return "Fornecedores";
    case AREAS.USER:
      return "Funcionários";
    case AREAS.PAYABLE:
      return "Contas a Pagar";
    case AREAS.RECEIVABLE:
      return "Contas a Receber";
    case AREAS.PURCHASE:
      return "Pedidos de Compra";
    case AREAS.SALE:
      return "Pedidos de Venda";
    case AREAS.REPORT:
      return "Relatórios";
    case AREAS.STOCK:
      return "Estoque";
    case AREAS.STOCK_TRANSACTIONS:
      return "Entradas e Saídas";
    case AREAS.TRANSFER_STOCK:
      return "Transferência de Estoque";
    case AREAS.RECEIVEDS:
      return "Recebimento no Almoxarifado";
    case AREAS.STOCK_TRANSITORY:
      return "Estoque Transitório";
    case AREAS.LASTNFENUMBER:
      return "Últimos Números de NF-e";
    case AREAS.WAREHOUSE:
      return "Depósitos";
    case AREAS.PURCHASE_WAREHOUSE:
      return "Pedidos de Compra no Almoxarifado";
    case AREAS.APLICACAO:
      return "Aplicação";
    case AREAS.DEVOLUCAO:
      return "Devolução";
    case AREAS.DASHBOARD_REPORTS:
      return "Relatórios do Dashboard";
    case AREAS.BATCH_OF_SETTLEMENTS:
      return "Lotes de Baixa";
    default:
      return "";
  }
};

export const NAO_SIM = {
  NAO: "0",
  SIM: "1",
};

export const findNaoSim = (naoSim) => {
  switch (naoSim) {
    case NAO_SIM.NAO:
      return "Não";
    case NAO_SIM.SIM:
      return "Sim";
    default:
      return "";
  }
};

export const yupSchema = yup.object().shape({});

export const initialValues = {};

import * as yup from "yup";

export const TIPO_BAIXA = {
  PARCIAL: "P",
  TOTAL: "T",
};

export const yupSchema = yup.object().shape({
  nome: yup.string().required("O campo 'nome' é obrigatório"),
  descricao: yup.string().required("O campo 'descrição' é obrigatório"),
  account: yup
    .string()
    .required("O campo 'conta' é obrigatório")
    .matches(/^[0-9a-fA-F]{24}$/, "ID de conta inválido"), // Valida um ObjectId do MongoDB
  dataPagamento: yup.date().nullable().typeError("Data de pagamento inválida"), // Permite campo nulo
  payables: yup
    .array()
    .of(
      yup.object().shape({
        payable_id: yup
          .string()
          .nullable()
          .matches(/^[0-9a-fA-F]{24}$/, "ID de 'payable' inválido"),
        tipoDeBaixa: yup
          .string()
          .oneOf(Object.values(TIPO_BAIXA), "Tipo de baixa inválido")
          .required("O campo 'tipoDeBaixa' é obrigatório"),
        valor: yup
          .number()
          .required("O campo 'valor' é obrigatório")
          .typeError("Valor deve ser um número"),
        dataBaixa: yup
          .date()
          .required("O campo 'dataBaixa' é obrigatório")
          .typeError("Data de baixa inválida"),
      })
    )
    .nullable(), // Campo opcional e permite valores nulos
  receivables: yup
    .array()
    .of(
      yup.object().shape({
        receivable_id: yup
          .string()
          .nullable()
          .matches(/^[0-9a-fA-F]{24}$/, "ID de 'receivable' inválido"),
        tipoDeBaixa: yup
          .string()
          .oneOf(Object.values(TIPO_BAIXA), "Tipo de baixa inválido")
          .required("O campo 'tipoDeBaixa' é obrigatório"),
        valor: yup
          .number()
          .required("O campo 'valor' é obrigatório")
          .typeError("Valor deve ser um número"),
        dataBaixa: yup
          .date()
          .required("O campo 'dataBaixa' é obrigatório")
          .typeError("Data de baixa inválida"),
      })
    )
    .nullable(),
});

export const initialValues = {
  nome: "",
  descricao: "",
  account: "",
  dataPagamento: null,
  payables: [],
  receivables: [],
};

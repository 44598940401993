import HttpService from "./http.service";

class TransStockService {
  listByWarehouse = async (warehouseId, sortStr = null, filterStr = null) => {
    const endpoint = `/trans-estoque/warehouse/${warehouseId}?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(endpoint);
  };

  listForDashboard = async (
    warehouseId,
    sortStr = null,
    filterStr = null,
    tipo = "origem"
  ) => {
    const endpoint = `/trans-estoque/dashboard/${warehouseId}?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}&tipo=${tipo}`;
    return await HttpService.get(endpoint);
  };

  get = async (id) => {
    const endpoint = "/trans-estoque";
    return await HttpService.get(`${endpoint}/${id}`);
  };

  create = async (data) => {
    const endpoint = "/trans-estoque";
    return await HttpService.post(endpoint, data);
  };

  update = async (id, data) => {
    const endpoint = "/trans-estoque";
    return await HttpService.put(`${endpoint}/${id}`, data);
  };

  delete = async (id) => {
    const endpoint = "/trans-estoque";
    return await HttpService.delete(`${endpoint}/${id}`);
  };
}

export default new TransStockService();
